import React, { useState, useEffect, forwardRef } from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import DateRange from "@mui/icons-material/DateRange";
import AccessTime from "@mui/icons-material/AccessTime";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Loader from "components/Loader/Loader";

import { dailySalesChart } from "variables/charts.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import moment from "moment";
import "moment/locale/es";

import { Redirect } from 'react-router-dom';

moment.locale("es");

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();

  const [LlamadasDiarias, setLlamadasDiarias] = useState(0);
  const [LlamadasMensuales, setLlamadasMensuales] = useState(0);

  const [RetencionesDiarias, setRetencionesDiarias] = useState(0);
  const [RetencionesMensuales, setRetencionesMensuales] = useState(0);

  const [PromedioRetencionesDiarias, setPromedioRetencionesDiarias] = useState(0);
  const [PromedioRetencionesMensuales, setPromedioRetencionesMensuales] = useState(0);

  const [LlamadasDias, setLlamadasDias] = useState([]);
  const [LlamadasNombreDias, setLlamadasNombreDias] = useState([]);
  const [LlamadasOptions, setLlamadasOptions] = useState({...dailySalesChart.options});

  const [RetencionesDias, setRetencionesDias] = useState([]);
  const [RetencionesNombreDias, setRetencionesNombreDias] = useState([]);
  const [RetencionesOptions, setRetencionesOptions] = useState({...dailySalesChart.options});

  const [color, setColor] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [load, setLoad] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const sendSnackBar = (pmessage, pcolor) => {
    setOpenSnackbar(true);
    setColor(pcolor);
    setMessage(pmessage);
  };

  const GetIdUsuario = () => {
    return JSON.parse(localStorage.getItem('userRetencion')).IdUsuario;
  };

  const Login = async () => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify({UserName: process.env.REACT_APP_USER, Password: process.env.REACT_APP_PASS}),
            headers: {
                "Content-Type": "application/json"
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Login",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const GetIdSucursal = () => {
    return JSON.parse(localStorage.getItem('userRetencion')).IdSucursales;
  };

  const UsuarioActivo = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Usuario/UsuarioActivo",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const PromedioRetencion = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Reportes/PromedioRetencion",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const ObtieneRetencion = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Reportes/ObtieneRetencion",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const CuentaCaptura = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Reportes/CuentaCaptura",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const ReporteDiario = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Reportes/ReporteDiario",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  useEffect(() => {
    try{
      setLoad(true);

      let hoy = moment(Date.now());
      hoy.set({h: 0, m: 0, s: 0});

      let selectFechaInicio = moment(Date.now()).startOf('month');
      selectFechaInicio.set({h: 0, m: 0, s: 0});

      let selectFechaInicioSemana = moment(Date.now());
      selectFechaInicioSemana.set({h: 0, m: 0, s: 0});
      selectFechaInicioSemana.add(-8, 'd');

      let selectFechaFin = moment(Date.now());
      selectFechaFin.set({h: 23, m: 59, s: 59});

      let mensual = {
        FechaInicio: selectFechaInicio.format("YYYY/MM/DD HH:mm:ss"),
        FechaFin: selectFechaFin.format("YYYY/MM/DD HH:mm:ss"),
        IdSucursales: GetIdSucursal()
      };

      let semana = {
        FechaInicio: selectFechaInicioSemana.format("YYYY/MM/DD HH:mm:ss"),
        FechaFin: selectFechaFin.format("YYYY/MM/DD HH:mm:ss"),
        IdSucursales: GetIdSucursal()
      };

      let diario = {
        FechaInicio: hoy.format("YYYY/MM/DD HH:mm:ss"),
        FechaFin: selectFechaFin.format("YYYY/MM/DD HH:mm:ss"),
        IdSucursales: GetIdSucursal()
      };

      Login()
      .then(result => {
          if (result !== undefined) {
              UsuarioActivo({ IdUsuario: GetIdUsuario() }, result.token)
              .then(result => {
                if(result.activo === false){
                  localStorage.removeItem("userRetencion");
                  return <Redirect to='/admin'/>;
                }
                setLoad(false);
              });

              CuentaCaptura(diario, result.token)
              .then(result => {
                setLlamadasDiarias(parseInt(result.promedio.Promedio));
              });

              CuentaCaptura(mensual, result.token)
              .then(result => {
                setLlamadasMensuales(parseInt(result.promedio.Promedio));
              });

              ReporteDiario(semana, result.token)
              .then(result => {
                let capturas = result.capturas.map((element) => {
                  element.FechaCobro = element.FechaCobro === "" ? "" : moment(moment(element.FechaCobro, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY HH:mm:ss");
                  element.FinPrograma = element.FinPrograma === "" ? "" : moment(moment(element.FinPrograma, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY HH:mm:ss");
                  element.FechaInformacion = element.FechaInformacion === "" ? "" : moment(moment(element.FechaInformacion, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY HH:mm:ss");
                  element.FechaCapturista = element.FechaCapturista === "" ? "" : moment(moment(element.FechaCapturista, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY HH:mm:ss");
                  element.id = element.IdCuenta;

                  return element;
                });

                let dia = 0;
                let nombreDias = [];
                let dias = [];
                while (dia <= 7) {
                  let fecha = moment(Date.now());
                  fecha.set({h: 0, m: 0, s: 0});
                  fecha.add(-dia, 'd');
                  let nombreDia = fecha.format('dddd').substring(0,2).toUpperCase();

                  if(nombreDia !== 'DO') {
                    let registros = 0;
                    capturas.forEach(item => {
                      if(moment(item.FechaCapturista, "DD-MM-YYYY").isSame(fecha.toDate(), 'day')
                         && (item.IdEstatus === 1 || item.IdEstatus === 2)){
                        registros = registros + 1;
                      }
                    });

                    dias.push(registros);
                    nombreDias.push(nombreDia);
                  }

                  dia = dia + 1;
                }

                let cap = {...RetencionesOptions};
                cap.high = Math.max(...dias) + 10;

                setRetencionesOptions(cap);

                setRetencionesDias(dias.reverse());
                setRetencionesNombreDias(nombreDias.reverse());
              });

              ReporteDiario(semana, result.token)
              .then(result => {
                let capturas = result.capturas.map((element) => {
                  element.FechaCobro = element.FechaCobro === "" ? "" : moment(moment(element.FechaCobro, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY HH:mm:ss");
                  element.FinPrograma = element.FinPrograma === "" ? "" : moment(moment(element.FinPrograma, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY HH:mm:ss");
                  element.FechaInformacion = element.FechaInformacion === "" ? "" : moment(moment(element.FechaInformacion, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY HH:mm:ss");
                  element.FechaCapturista = element.FechaCapturista === "" ? "" : moment(moment(element.FechaCapturista, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY HH:mm:ss");
                  element.id = element.IdCuenta;

                  return element;
                });

                let dia = 0;
                let nombreDias = [];
                let dias = [];
                while (dia <= 7) {
                  let fecha = moment(Date.now());
                  fecha.set({h: 0, m: 0, s: 0});
                  fecha.add(-dia, 'd');
                  let nombreDia = fecha.format('dddd').substring(0,2).toUpperCase();

                  if(nombreDia !== 'DO') {
                    let registros = 0;
                    capturas.forEach(item => {
                      if(moment(item.FechaCapturista, "DD-MM-YYYY").isSame(fecha.toDate(), 'day')){
                        registros = registros + 1;
                      }
                    });

                    dias.push(registros);
                    nombreDias.push(nombreDia);
                  }

                  dia = dia + 1;
                }

                let cap = {...LlamadasOptions};
                cap.high = Math.max(...dias) + 10;

                setLlamadasOptions(cap);

                setLlamadasDias(dias.reverse());
                setLlamadasNombreDias(nombreDias.reverse());
                
                setLoad(false);
              });

              ObtieneRetencion(diario, result.token)
              .then(result => {
                setRetencionesDiarias(parseInt(result.promedio.Promedio));
              });

              ObtieneRetencion(mensual, result.token)
              .then(result => {
                setRetencionesMensuales(parseInt(result.promedio.Promedio));
              });

              PromedioRetencion(diario, result.token)
              .then(result => {
                setPromedioRetencionesDiarias(parseInt(result.promedio.Promedio));
              });

              PromedioRetencion(mensual, result.token)
              .then(result => {
                setPromedioRetencionesMensuales(parseInt(result.promedio.Promedio));
              });
          } else {
              sendSnackBar("Error de conexión", "error");
              setLoad(false);
          }
      }).catch(error => sendSnackBar("Error de conexión", "error"));
    } catch(error){
        sendSnackBar("Error de conexión", "error");
        setLoad(false);
    }
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="rose" stats icon>
              <p className={classes.cardCategory}>Llamadas diarias</p>
              <h3 className={classes.cardTitle}>{LlamadasDiarias}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Últimas 24 Horas
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="primary" stats icon>
              <p className={classes.cardCategory}>Llamadas mensuales</p>
              <h3 className={classes.cardTitle}>{LlamadasMensuales}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Último mes
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="rose" stats icon>
              <p className={classes.cardCategory}>Retención diaria</p>
              <h3 className={classes.cardTitle}>{RetencionesDiarias}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Últimas 24 Horas
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="primary" stats icon>
              <p className={classes.cardCategory}>Retención mensual</p>
              <h3 className={classes.cardTitle}>{RetencionesMensuales}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Último mes
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="rose" stats icon>
              <p className={classes.cardCategory}>Promedio retención diaria</p>
              <h3 className={classes.cardTitle}>{PromedioRetencionesDiarias}%</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Últimas 24 Horas
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardHeader color="primary" stats icon>
              <p className={classes.cardCategory}>Promedio retención mensual</p>
              <h3 className={classes.cardTitle}>{PromedioRetencionesMensuales}%</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Último mes
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader color="primary">
              <p>Llamadas</p>
              <ChartistGraph
                className="ct-chart"
                data={{
                  labels: LlamadasNombreDias,
                  series: [LlamadasDias]
                }}
                type="Line"
                options={LlamadasOptions}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}></h4>
              <p className={classes.cardCategory}></p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> Última semana
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader color="rose">
              <p>Retenciones</p>
              <ChartistGraph
                className="ct-chart"
                data={{
                  labels: RetencionesNombreDias,
                  series: [RetencionesDias]
                }}
                type="Line"
                options={RetencionesOptions}
                listener={dailySalesChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}></h4>
              <p className={classes.cardCategory}></p>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime /> Última semana
              </div>
            </CardFooter>
          </Card>
        </GridItem>     
      </GridContainer>
      <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={openSnackbar} onClose={handleClose} autoHideDuration={6000}>
            <Alert onClose={handleClose} severity={color} sx={{ width: '100%' }}>
                {message}
            </Alert>
      </Snackbar>
      <Loader load={load} />
    </div>
  );
}
