import React, { useEffect, forwardRef, useState } from 'react';

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import moment from "moment";
import "moment/locale/es";

import Loader from "components/Loader/Loader";

import { Redirect } from 'react-router-dom';

moment.locale("es");

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
    { id: 'IdCuenta', label: 'IdCuenta', minWidth: 100 },
    { id: 'NombreCliente', label: 'Nombre Cliente', minWidth: 100 },
    { id: 'Telefono', label: 'Telefono', minWidth: 100 },
    { id: 'Correo', label: 'Correo', minWidth: 100 },
    { id: 'NombreCapturista', label: 'Capturista', minWidth: 100 },
    { id: 'FechaCapturista', label: 'Fecha Captura', minWidth: 100 }
];

function Captura(){
    const [capturas, setCapturas] = useState([]);
    const [estatus, setEstatus] = useState([]);

    const [color, setColor] = useState("");
    const [message, setMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [load, setLoad] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const [IdCaptura, setIdCaptura] = useState("");
    const [IdCuenta, setIdCuenta] = useState("");

    const [NombreCliente, setNombreCliente] = useState("");
    const [Producto, setProducto] = useState("");
    const [UltimosDigitos, setUltimosDigitos] = useState("");
    const [FechaCobro, setFechaCobro] = useState("");
    const [AltaRenovacion, setAltaRenovacion] = useState("");

    const [Correo, setCorreo] = useState("");
    const [RFC, setRFC] = useState("");
    const [Telefono, setTelefono] = useState("");
    const [CanalVenta, setCanalVenta] = useState("");
    const [FinPrograma, setFinPrograma] = useState("");
    const [FechaInformacion, setFechaInformacion] = useState("");
    const [MesesRestantes, setMesesRestantes] = useState("");
    const [MontoCobrado, setMontoCobrado] = useState("");
    const [LineaCredito, setLineaCredito] = useState("");
    const [FechaCorte, setFechaCorte] = useState("");

    const [IdEstatus, setIdEstatus] = useState("1");
    const [Observaciones, setObservaciones] = useState("");

    const [tipo, setTipo] = useState('rfc');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const getUser = () => {
        return JSON.parse(localStorage.getItem("userRetencion")).IdUsuario;
    };

    const sendSnackBar = (pmessage, pcolor) => {
        setOpenSnackbar(true);
        setColor(pcolor);
        setMessage(pmessage);
    };

    const selectRow = (e, row) => {
        e.stopPropagation(); 
        e.preventDefault();

        let elemento = capturas.map(element => {
            if(element.IdCaptura === row.IdCaptura)
            {
                element.select = true;
                return element;
            }
            else
            {
                element.select = false;
                return element;
            }
        });

        setCapturas(elemento);
        limpiaValores();

        let cap = elemento.find(item => item.IdCaptura === row.IdCaptura);

        setIdCaptura(cap.IdCaptura);
        setIdCuenta(cap.IdCuenta)

        setNombreCliente(cap.NombreCliente);
        setProducto(cap.Producto);
        setUltimosDigitos(cap.UltimosDigitos);
        setFechaCobro(cap.FechaCobro);
        setAltaRenovacion(cap.AltaRenovacion);

        setCorreo(cap.Correo);
        setRFC(cap.RFC);
        setTelefono(cap.Telefono);
        setCanalVenta(cap.CanalVenta);
        setFinPrograma(cap.FinPrograma);
        setFechaInformacion(cap.FechaInformacion);
        setMesesRestantes(cap.MesesRestantes);
        setMontoCobrado(cap.MontoCobrado);
        setLineaCredito(cap.LineaCredito);
        setFechaCorte(cap.FechaCorte);

        setIdEstatus(cap.IdEstatus);
        setObservaciones(cap.Observaciones);
    };

    const tipoNumero = (valor) => {
        let num = 0;

        switch(valor)
        {
            case "rfc":
                num = 1;
                break;
            case "telefono":
                num = 2;
                break
            case "correo":
                num = 3;
                break
            case "nombre":
                num = 4;
                break
        }

        return num;
    };

    const buscar = (event) => {
        event.preventDefault();
        setLoad(true);

        limpiaValores();

        let busqueda = document.getElementById("busqueda").value;

        if(busqueda !== ""){
            Login()
            .then(result => {
                if(result !== undefined){
                    let params = {
                        Indicador: busqueda,
                        Tipo: tipoNumero(tipo),
                        Capturista: getUser()
                    };
    
                    ObtieneCapturasCapturistas(result.token, params)
                    .then(result => {
                        let capturas = result.capturas.map((element) => {
                            element.FechaCobro = element.FechaCobro === "" ? "" : moment(moment(element.FechaCobro, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                            element.FinPrograma = element.FinPrograma === "" ? "" : moment(moment(element.FinPrograma, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                            element.FechaInformacion = element.FechaInformacion === "" ? "" : moment(moment(element.FechaInformacion, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                            element.FechaCorte = element.FechaCorte === "" ? "" : moment(moment(element.FechaCorte, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                            element.FechaCapturista = element.FechaCapturista === "" ? "" : moment(moment(element.FechaCapturista, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY HH:mm:ss");
                            return element;
                        });
                        setCapturas(capturas);
                        setLoad(false);
    
                    }).catch(error => sendSnackBar("Error de conexión", "error"));
                } else {
                    sendSnackBar("Error de conexión", "error");
                }
            }).catch(error => sendSnackBar("Error de conexión", "error"));
        } else {
            sendSnackBar("Debes agregar un elemento a buscar", "error");
            setLoad(false);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const SnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackbar(false);
    };

    const DialogClose = () => {
        setOpenDialog(false);
        setTimeout(function(){ window.location.reload(false); }, 1000);
    };

    const Login = async () => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify({UserName: process.env.REACT_APP_USER, Password: process.env.REACT_APP_PASS}),
                headers: {
                    "Content-Type": "application/json"
                }
            };

            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Login",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const ActualizaCaptura = async (data, token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Captura/ActualizaCaptura",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const ObtieneCapturasCapturistas = async (token, data) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Captura/ObtieneCapturasCapturistas",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const ObtieneEstatus = async (token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Estatus/ObtieneEstatus",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const ValidaDatos = () => {
        let datos = { IdCaptura: parseInt(IdCaptura), IdEstatus: parseInt(IdEstatus), Observaciones,
            FechaCapturista: moment(Date.now()).toDate(), Capturista: getUser()};
        
        return datos;
    }

    const EnviaDatos = () => {
        try{
            setLoad(true);
            
            let datos = ValidaDatos();
            
            if(datos.error === undefined && IdCaptura !== ""){
                Login()
                .then(result => {
                    if(result !== undefined){
                        ActualizaCaptura(datos, result.token)
                        .then(result => {
                            if(result.success){
                                sendSnackBar("Registro exitoso", "success");
                                setOpenSnackbar(true);
                                setOpenDialog(true);
                            } else {
                                sendSnackBar("Error de conexión", "error");
                            }
            
                            setLoad(false);
                        }).catch(error => sendSnackBar("Error de conexión", "error"));
                    } else {
                        sendSnackBar("Error de conexión", "error");
                        setLoad(false);
                    }
                }).catch(error => sendSnackBar("Error de conexión", "error"));
            } else {
                sendSnackBar("Todos los valores son obligatorios", "error");
                setLoad(false);
            }
        } catch(error){
            sendSnackBar("Error de conexión", "error");
            setLoad(false);
        }
    };

    const limpiaValores = () => {
        setIdCaptura("");
        setIdCuenta("")

        setNombreCliente("");
        setProducto("");
        setUltimosDigitos("");
        setFechaCobro("");
        setAltaRenovacion("");

        setCorreo("");
        setRFC("");
        setTelefono("");
        setCanalVenta("");
        setFinPrograma("");
        setFechaInformacion("");
        setMesesRestantes("");
        setMontoCobrado("");
        setLineaCredito("");
        setFechaCorte("");

        setIdEstatus("1");
        setObservaciones("");
    };

    const UsuarioActivo = async (data, token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };

            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Usuario/UsuarioActivo",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }

        return dataResponse;
    };

    const GetIdUsuario = () => {
        return JSON.parse(localStorage.getItem('userRetencion')).IdUsuario;
    };

    useEffect(() => {
        Login()
        .then(result => {
            if(result !== undefined){
                let token = result.token;

                UsuarioActivo({ IdUsuario: GetIdUsuario() },token)
                .then(result => {
                    if(result.activo === false){
                        localStorage.removeItem("userRetencion");
                        return <Redirect to='/admin'/>;
                    }
                });

                ObtieneEstatus(token)
                .then(result => {
                    setEstatus(result.estatus);
                }).catch(error => sendSnackBar("Error de conexión", "error"));
            } else {
                sendSnackBar("Error de conexión", "error");
            }
        }).catch(error => sendSnackBar("Error de conexión", "error"));
    }, []);

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={6} xl={6}>
                    <Paper
                        style={{ marginBottom: "30px" }}
                        component="form"
                        sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                        >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Buscar..."
                            inputProps={{ 'aria-label': 'search google maps' }}
                            id="busqueda"
                        />
                        <IconButton onClick={buscar} sx={{ p: '10px' }} aria-label="search">
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} xl={6}>
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
                            <FormControlLabel
                                checked={tipo === 'rfc'}
                                onChange={(event) => setTipo(event.target.value)}
                                value="rfc" 
                                control={<Radio />} 
                                label="RFC" />
                            <FormControlLabel
                                checked={tipo === 'telefono'}
                                onChange={(event) => setTipo(event.target.value)}
                                value="telefono" 
                                control={<Radio />} 
                                label="Teléfono" />
                            <FormControlLabel
                                checked={tipo === 'correo'}
                                onChange={(event) => setTipo(event.target.value)}
                                value="correo" 
                                control={<Radio />} 
                                label="Correo" />
                            <FormControlLabel
                                checked={tipo === 'nombre'}
                                onChange={(event) => setTipo(event.target.value)}
                                value="nombre" 
                                control={<Radio />} 
                                label="Nombre" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                { capturas.length > 0 && 
                    (
                        <Grid item xs={12} sm={12} xl={12} style={{ marginBottom: "30px" }}>
                            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: 440 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {capturas.map((row) => {
                                            return (
                                                <TableRow onClick={(e) => selectRow(e, row)} selected={row.select} hover={true} tabIndex={-1} key={capturas.IdCaptura}>
                                                    {columns.map((column) => {
                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {  
                                                                    row[column.id]
                                                                }
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={capturas.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={(event, newPage) => setPage(newPage)}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </Grid>
                    )
                }
            </Grid>
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Referencia
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={IdCuenta} disabled={true} label="Id Cuenta" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                </Grid>
            </Card>       
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Datos cliente
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={NombreCliente} disabled={true} label="Nombre del cliente" variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={Producto} disabled={true} label="Producto" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={UltimosDigitos} disabled={true} label="Últimos 6 dígitos" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={FechaCobro} disabled={true} label="Fecha de cobro" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={AltaRenovacion} disabled={true} label="Alta renovacion vigente" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                </Grid>
            </Card>
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Autenticación
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={Correo} disabled={true} label="Correo" type="email" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="RFC" value={RFC} disabled={true} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Teléfono" disabled={true} value={Telefono} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                </Grid>
            </Card>
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Retención
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={CanalVenta} disabled={true} label="Canal de venta" type="email" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Fin programa cero" value={FinPrograma} disabled={true} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Fecha informacion" value={FechaInformacion} disabled={true} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Meses restantes con P0" value={MesesRestantes} disabled={true} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Monto cobrado" value={MontoCobrado} disabled={true} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Linea de credito" disabled={true} value={LineaCredito} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Fecha corte" value={FechaCorte} disabled={true} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                </Grid>
            </Card>
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Estatus
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField
                            select
                            label="Estatus*"
                            value={IdEstatus} 
                            onChange={(event) => setIdEstatus(event.target.value)} 
                            style={{ width: "100%" }}
                        >
                            {estatus.map((option) => (
                                <MenuItem key={option.IdEstatus} value={option.IdEstatus}>
                                    {option.Nombre}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Observaciones" value={Observaciones} onChange={(event) => setObservaciones(event.target.value)} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                </Grid>
            </Card>
            <Grid container
                direction="row-reverse"
                justifyContent="flex-start"
                alignItems="center">
                <Grid item xs={12} sm={12} xl={3}>
                    <Button
                        color="info"
                        variant="contained"
                        onClick={EnviaDatos}
                        style={{ float: "right" }}
                        size="large"
                        >
                            { "Enviar" }
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={DialogClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Registro exitoso"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {`Referencia: ${IdCuenta}`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={DialogClose}>Aceptar</Button>
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={openSnackbar} onClose={SnackbarClose} autoHideDuration={6000}>
                <Alert onClose={SnackbarClose} severity={color} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Loader load={load} />
        </>
    );
}

export default Captura;