import React from 'react';
import { Redirect } from 'react-router-dom';

class LogoutPage extends React.Component {
    render(){
        localStorage.removeItem("userRetencion");

        return <Redirect to='/admin'/>;
    }
}

export default LogoutPage;