import React, { useEffect, forwardRef, useState } from 'react';

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import FormControl from '@mui/material/FormControl';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import moment from "moment";
import "moment/locale/es";

import Loader from "components/Loader/Loader";

import { Redirect } from 'react-router-dom';

moment.locale("es");

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Alta(){
    const [estatus, setEstatus] = useState([]);

    const [color, setColor] = useState("");
    const [message, setMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const [load, setLoad] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const [NombreCliente, setNombreCliente] = useState("");
    const [Producto, setProducto] = useState("");
    const [UltimosDigitos, setUltimosDigitos] = useState("");
    const [FechaCobro, setFechaCobro] = useState("");
    const [AltaRenovacion, setAltaRenovacion] = useState("");

    const [Correo, setCorreo] = useState("");
    const [RFC, setRFC] = useState("");
    const [Telefono, setTelefono] = useState("");
    const [CanalVenta, setCanalVenta] = useState("");
    const [FinPrograma, setFinPrograma] = useState("");
    const [FechaInformacion, setFechaInformacion] = useState("");
    const [MesesRestantes, setMesesRestantes] = useState("");
    const [MontoCobrado, setMontoCobrado] = useState(0);
    const [LineaCredito, setLineaCredito] = useState(0);
    const [FechaCorte, setFechaCorte] = useState("");

    const [IdEstatus, setIdEstatus] = useState("1");
    const [Observaciones, setObservaciones] = useState("");

    const getUser = () => {
        return JSON.parse(localStorage.getItem("userRetencion")).IdUsuario;
    };

    const sendSnackBar = (pmessage, pcolor) => {
        setOpenSnackbar(true);
        setColor(pcolor);
        setMessage(pmessage);
    };

    const SnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenSnackbar(false);
    };

    const DialogClose = () => {
        setOpenDialog(false);
        setTimeout(function(){ window.location.reload(false); }, 1000);
    };

    const Login = async () => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify({UserName: process.env.REACT_APP_USER, Password: process.env.REACT_APP_PASS}),
                headers: {
                    "Content-Type": "application/json"
                }
            };

            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Login",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const InsertaCaptura = async (data, token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Captura/InsertaCaptura",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const ObtieneEstatus = async (token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };
    
            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Estatus/ObtieneEstatus",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }
    
        return dataResponse;
    };

    const ValidaDatos = () => {
        let datos = { NombreCliente, Producto, UltimosDigitos, FechaCobro, AltaRenovacion, Correo, RFC, 
            Telefono, CanalVenta, FinPrograma, FechaInformacion, MesesRestantes, FechaCorte,
            MontoCobrado: parseInt(MontoCobrado), LineaCredito: parseInt(LineaCredito), 
            Observaciones, IdEstatus: parseInt(IdEstatus), FechaCapturista: moment(Date.now()).toDate(), 
            Capturista: getUser()};
        
        return datos;
    }

    const EnviaDatos = () => {
        try{
            setLoad(true);
            
            let datos = ValidaDatos();
            
            if(datos.error === undefined){
                Login()
                .then(result => {
                    if(result !== undefined){
                        InsertaCaptura(datos, result.token)
                        .then(result => {
                            if(result.success){
                                sendSnackBar("Registro exitoso", "success");
                                setOpenSnackbar(true);
                                setOpenDialog(true);
                            } else {
                                sendSnackBar("Error de conexión", "error");
                            }
            
                            setLoad(false);
                        }).catch(error => sendSnackBar("Error de conexión", "error"));
                    } else {
                        sendSnackBar("Error de conexión", "error");
                        setLoad(false);
                    }
                }).catch(error => sendSnackBar("Error de conexión", "error"));
            } else {
                sendSnackBar("Todos los valores son obligatorios", "error");
                setLoad(false);
            }
        } catch(error){
            sendSnackBar("Error de conexión", "error");
            setLoad(false);
        }
    };

    const UsuarioActivo = async (data, token) => {
        let dataResponse;
        try {
            let header = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token
                }
            };

            let response = await fetch(
                process.env.REACT_APP_URL_APICORE + "Usuario/UsuarioActivo",
                header
            );

            dataResponse = await response.json();
        } catch (error) {
            sendSnackBar("Error de conexión", "error");
        }

        return dataResponse;
    };

    const GetIdUsuario = () => {
        return JSON.parse(localStorage.getItem('userRetencion')).IdUsuario;
    };

    useEffect(() => {
        Login()
        .then(result => {
            if(result !== undefined){
                let token = result.token;

                UsuarioActivo({ IdUsuario: GetIdUsuario() },token)
                .then(result => {
                    if(result.activo === false){
                        localStorage.removeItem("userRetencion");
                        return <Redirect to='/admin'/>;
                    }
                });

                ObtieneEstatus(token)
                .then(result => {
                    setEstatus(result.estatus);
                }).catch(error => sendSnackBar("Error de conexión", "error"));
            } else {
                sendSnackBar("Error de conexión", "error");
            }
        }).catch(error => sendSnackBar("Error de conexión", "error"));
    }, []);

    return (
        <>      
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Datos cliente
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={NombreCliente} onChange={(event) => setNombreCliente(event.target.value)} inputProps={{ maxLength: 255 }} label="Nombre del cliente" variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={Producto} onChange={(event) => setProducto(event.target.value)} inputProps={{ maxLength: 255 }} label="Producto" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={UltimosDigitos} onChange={(event) => setUltimosDigitos(event.target.value)} inputProps={{ maxLength: 6 }} label="Últimos 6 dígitos" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <FormControl style={{ width: "100%", marginTop: "-9px" }}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        format="yyyy/MM/dd"
                                        label="Fecha de cobro"
                                        value={FechaCobro}
                                        onChange={(date) => setFechaCobro(date)}
                                        slotProps={{ textField: { fullWidth: true } }}
                                        renderInput={(props) => <TextField {...props} />}
                                    />
                                </DemoContainer>
                            </FormControl>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={AltaRenovacion} onChange={(event) => setAltaRenovacion(event.target.value)} inputProps={{ maxLength: 100 }} label="Alta renovacion vigente" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                </Grid>
            </Card>
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Autenticación
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={Correo} onChange={(event) => setCorreo(event.target.value)} inputProps={{ maxLength: 100 }} label="Correo" type="email" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="RFC" value={RFC} onChange={(event) => setRFC(event.target.value)} inputProps={{ maxLength: 13 }} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Teléfono" value={Telefono} onChange={(event) => setTelefono(event.target.value)} inputProps={{ maxLength: 10 }} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                </Grid>
            </Card>
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Retención
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField value={CanalVenta} onChange={(event) => setCanalVenta(event.target.value)} inputProps={{ maxLength: 255 }} label="Canal de venta" type="email" variant="outlined" style={{ width: "100%" }} /> 
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <FormControl style={{ width: "100%", marginTop: "-9px" }}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        format="yyyy/MM/dd"
                                        label="Fin programa cero"
                                        value={FinPrograma}
                                        onChange={(date) => setFinPrograma(date)}
                                        slotProps={{ textField: { fullWidth: true } }}
                                        renderInput={(props) => <TextField {...props} />}
                                    />
                                </DemoContainer>
                            </FormControl>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <FormControl style={{ width: "100%", marginTop: "-9px" }}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        format="yyyy/MM/dd"
                                        label="Fecha informacion"
                                        value={FechaInformacion}
                                        onChange={(date) => setFechaInformacion(date)}
                                        slotProps={{ textField: { fullWidth: true } }}
                                        renderInput={(props) => <TextField {...props} />}
                                    />
                                </DemoContainer>
                            </FormControl>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Meses restantes con P0" value={MesesRestantes} onChange={(event) => setMesesRestantes(event.target.value)} inputProps={{ maxLength: 45 }} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Monto cobrado" value={MontoCobrado} onChange={(event) => setMontoCobrado(event.target.value)} inputProps={{ maxLength: 31, type: "number" }} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Linea de credito" value={LineaCredito} onChange={(event) => setLineaCredito(event.target.value)} inputProps={{ maxLength: 31, type: "number" }} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <FormControl style={{ width: "100%", marginTop: "-9px" }}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        format="yyyy/MM/dd"
                                        label="Fecha corte"
                                        value={FechaCorte}
                                        onChange={(date) => setFechaCorte(date)}
                                        slotProps={{ textField: { fullWidth: true } }}
                                        renderInput={(props) => <TextField {...props} />}
                                    />
                                </DemoContainer>
                            </FormControl>
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Card>
            <Card style={{ marginBottom: "30px" }}>
                <Grid container spacing={3} style={{ padding: "30px" }}>
                    <Grid item xs={12} sm={12} xl={12}>
                        <Typography variant="h6">
                            Estatus
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField
                            select
                            label="Estatus*"
                            value={IdEstatus} 
                            onChange={(event) => setIdEstatus(event.target.value)} 
                            style={{ width: "100%" }}
                        >
                            {estatus.map((option) => (
                                <MenuItem key={option.IdEstatus} value={option.IdEstatus}>
                                    {option.Nombre}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6} xl={4}>
                        <TextField label="Observaciones" value={Observaciones} onChange={(event) => setObservaciones(event.target.value)} inputProps={{ maxLength: 200 }} variant="outlined" style={{ width: "100%" }} />
                    </Grid>
                </Grid>
            </Card>
            <Grid container
                direction="row-reverse"
                justifyContent="flex-start"
                alignItems="center">
                <Grid item xs={12} sm={12} xl={3}>
                    <Button
                        color="info"
                        variant="contained"
                        onClick={EnviaDatos}
                        style={{ float: "right" }}
                        size="large"
                        >
                            { "Enviar" }
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                open={openDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={DialogClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Registro exitoso"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {`Teléfono: ${Telefono}`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={DialogClose}>Aceptar</Button>
                </DialogActions>
            </Dialog>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={openSnackbar} onClose={SnackbarClose} autoHideDuration={6000}>
                <Alert onClose={SnackbarClose} severity={color} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            <Loader load={load} />
        </>
    );
}

export default Alta;