import React, { useState, forwardRef } from "react";
import { DataGrid } from '@mui/x-data-grid';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";

import Loader from "components/Loader/Loader";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import moment from "moment";
import "moment/locale/es";

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

moment.locale("es");

export default function ReporteCapturaInvex() {
  
  const useStyles = makeStyles(theme => ({
    ...dashboardStyle,
    cardTitle: {
      marginTop: "0",
      minHeight: "auto",
      fontWeight: "300",
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      marginBottom: "3px",
      textDecoration: "none"
    },
    select: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      color: "#D2D2D2",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "1.42857",
      letterSpacing: "unset",
      "&:before": {
        borderColor: "#D2D2D2",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "1.42857",
        letterSpacing: "unset"
      },
      "&:after": {
        borderColor: "#D2D2D2",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "1.42857",
        letterSpacing: "unset"
      }
    },
    container: {
      textAlign: "center"
    },
    content: {
      display: "inline-flex",
      padding: "10px",
      alignItems: "center"
    },
    paddingBottomCard: {
      paddingBottom: "40px"
    },
    marginRight: {
      marginRight: "10px"
    },
    marginBottom: {
      marginBottom: "50px"
    },
    formControl: {
      marginTop: theme.spacing(3.5),
      minWidth: "100%"
    },
    formControl2: {
      minWidth: "100%",
      marginTop: theme.spacing(5)
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  }));

  const classes = useStyles();

  const [selectFechaInicio, setSelectedFechaInicio] = useState(new Date());
  const [selectFechaFin, setSelectedFechaFin] = useState(new Date());

  const [load, setLoad] = useState(false);

  const [color, setColor] = useState("");
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [columnReporte] = useState([
    { title: "IdCuenta", field: "IdCuenta", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Nombre Cliente", field: "NombreCliente", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Producto", field: "Producto", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Ultimos Digitos", field: "UltimosDigitos", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Fecha Cobro", field: "FechaCobro", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Alta Renovacion", field: "AltaRenovacion", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Correo", field: "Correo", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "RFC", field: "RFC", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Telefono", field: "Telefono", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Canal Venta", field: "CanalVenta", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Fin Programa", field: "FinPrograma", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Fecha Informacion", field: "FechaInformacion", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Meses Restantes", field: "MesesRestantes", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Monto Cobrado", field: "MontoCobrado", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Linea Credito", field: "LineaCredito", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Observaciones", field: "Observaciones", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Fecha Capturista", field: "FechaCapturista", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Nombre Capturista", field: "NombreCapturista", type: "string", headerClassName: 'super-app-theme--header', width: 200 },
    { title: "Estatus", field: "Estatus", type: "string", headerClassName: 'super-app-theme--header', width: 100 },
    { title: "Fecha Corte", field: "FechaCorte", type: "string", headerClassName: 'super-app-theme--header', width: 100 }
  ]);
  const [reporte, setReporte] = useState([]);

  const sendSnackBar = (pmessage, pcolor) => {
    setOpenSnackbar(true);
    setColor(pcolor);
    setMessage(pmessage);
  };
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const convertToCSV = (objArray) => {
    const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";

    for (let i = 0; i < array.length; i++) {
      str += Object.keys(array[i]).map(function(k) { return array[i][k] }).join(",") + "\r\n";
    }

    return str;
  };

  const exportCSVFile = (headers, items, fileName) => {
    if (headers) {
        items.unshift(headers);
    }

    const jsonObject = JSON.stringify(items);
    const csv = convertToCSV(jsonObject);
    const exportName = fileName + ".csv";
    var encoding = "data:text/csv;charset=utf-8,%EF%BB%BF";

    const link = document.createElement("a");
    link.setAttribute("href", encoding + encodeURI(csv));
    link.setAttribute("download", exportName);
    link.style.visibility = "hidden";
    link.click();
  };

  const exportData = (JSONData) => {
    let arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
    let reportTitle = "Reporte_General";
    let headers = {};

    arrData = arrData.map(item => { 
      return { 
        IdCuenta: item.IdCuenta.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "").replaceAll(`"`, ""),
        NombreCliente: item.NombreCliente.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "").replaceAll(`"`, ""),
        Producto: item.Producto.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "").replaceAll(`"`, ""),
        UltimosDigitos: item.UltimosDigitos.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "").replaceAll(`"`, ""),
        FechaCobro: item.FechaCobro.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "").replaceAll(`"`, ""),
        AltaRenovacion: item.AltaRenovacion.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "").replaceAll(`"`, ""),
        Correo: item.Correo.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "").replaceAll(`"`, ""),
        RFC: item.RFC.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "").replaceAll(`"`, ""),
        Telefono: item.Telefono.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "").replaceAll(`"`, ""),
        CanalVenta: item.CanalVenta.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "").replaceAll(`"`, ""),
        FinPrograma: item.FinPrograma.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "").replaceAll(`"`, ""),
        FechaInformacion: item.FechaInformacion.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "").replaceAll(`"`, ""),
        MesesRestantes: item.MesesRestantes.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "").replaceAll(`"`, ""),
        MontoCobrado: item.MontoCobrado.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "").replaceAll(`"`, ""),
        LineaCredito: item.LineaCredito.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "").replaceAll(`"`, ""),
        Observaciones: item.Observaciones.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "").replaceAll(`"`, ""),
        FechaCapturista: item.FechaCapturista.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "").replaceAll(`"`, ""),
        NombreCapturista: item.NombreCapturista.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "").replaceAll(`"`, ""),
        Estatus: item.Estatus.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "").replaceAll(`"`, ""),
        FechaCorte: item.FechaCorte.replaceAll(/(\r\n|\n|\r)/gm, "").replaceAll(",", "").replaceAll(`"`, "")
      };
    });

    headers = {
        IdCuenta: "IdCuenta",
        NombreCliente: "Nombre Cliente",
        Producto: "Producto",
        UltimosDigitos: "Ultimos Digitos",
        FechaCobro: "FechaCobro",
        AltaRenovacion: "Alta Renovacion",
        Correo: "Correo",
        RFC: "RFC",
        Telefono: "Telefono",
        CanalVenta: "Canal Venta",
        FinPrograma: "Fin Programa",
        FechaInformacion: "Fecha Informacion",
        MesesRestantes: "Meses Restantes",
        MontoCobrado: "Monto Cobrado",
        LineaCredito: "Linea Credito",
        Observaciones: "Observaciones",
        FechaCapturista: "Fecha Capturista",
        NombreCapturista: "Nombre Capturista",
        Estatus: "Estatus",
        FechaCorte: "Fecha Corte"
    };
    
    exportCSVFile(headers, arrData, reportTitle);
  };

  const GetIdSucursal = () => {
    return JSON.parse(localStorage.getItem('userRetencion')).IdSucursales;
  };

  const handeSubmit = event => {
    event.preventDefault();
    try{
        setLoad(true);

        let fechaInicio = moment(selectFechaInicio);
        fechaInicio.set({h: 0, m: 0, s: 0});

        let fechaFin = moment(selectFechaFin);
        fechaFin.set({h: 23, m: 59, s: 59});

        Login()
        .then(result => {
            if (result !== undefined) {
                let body = {
                    FechaInicio: fechaInicio.format("YYYY/MM/DD HH:mm:ss"),
                    FechaFin: fechaFin.format("YYYY/MM/DD HH:mm:ss"),
                    IdSucursales: GetIdSucursal()
                };

                ReporteCapturaInvex(body, result.token)
                .then(result => {
                      let capturas = result.capturas.map((element) => {
                        element.FechaCobro = element.FechaCobro === "" ? "" : moment(moment(element.FechaCobro, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                        element.FinPrograma = element.FinPrograma === "" ? "" : moment(moment(element.FinPrograma, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                        element.FechaInformacion = element.FechaInformacion === "" ? "" : moment(moment(element.FechaInformacion, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                        element.FechaCorte = element.FechaCorte === "" ? "" : moment(moment(element.FechaCorte, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY");
                        element.FechaCapturista = element.FechaCapturista === "" ? "" : moment(moment(element.FechaCapturista, process.env.REACT_APP_FECHA).toDate()).format("DD-MM-YYYY HH:mm:ss");

                        element.id = Math.random() * 10;

                        return element;
                      });
                    setReporte(capturas);
                    setLoad(false);
                });
            } else {
                sendSnackBar("Error de conexión", "error");
                setLoad(false);
            }
        }).catch(error => sendSnackBar("Error de conexión", "error"));
    } catch(error){
        sendSnackBar("Error de conexión", "error");
        setLoad(false);
    }
  };

  const ReporteCapturaInvex = async (data, token) => {
    let dataResponse;
    try {
        let header = {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            }
        };

        let response = await fetch(
            process.env.REACT_APP_URL_APICORE + "Reportes/ReporteCapturaInvex",
            header
        );

        dataResponse = await response.json();
    } catch (error) {
        sendSnackBar("Error de conexión", "error");
    }

    return dataResponse;
  };

  const Login = async () => {
      let dataResponse;
      try {
          let header = {
              method: "POST",
              body: JSON.stringify({UserName: process.env.REACT_APP_USER, Password: process.env.REACT_APP_PASS}),
              headers: {
                  "Content-Type": "application/json"
              }
          };

          let response = await fetch(
              process.env.REACT_APP_URL_APICORE + "Login",
              header
          );

          dataResponse = await response.json();
      } catch (error) {
          sendSnackBar("Error de conexión", "error");
      }

      return dataResponse;
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid className={classes.marginBottom}>
          <Grid container className={classes.paddingBottomCard}>
            <GridItem xs={12} sm={12} md={4}>
              <FormControl className={classes.formControl}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    format="yyyy/MM/dd"
                    label="Fecha inicio"
                    value={selectFechaInicio}
                    onChange={(date) => setSelectedFechaInicio(date)}
                    slotProps={{ textField: { fullWidth: true } }}
                    renderInput={(props) => <TextField {...props} />}
                  />
                </DemoContainer>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <FormControl className={classes.formControl}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Fecha Final"
                    format="yyyy/MM/dd"
                    value={selectFechaFin}
                    onChange={(date) => setSelectedFechaFin(date)}
                    slotProps={{ textField: { fullWidth: true } }}
                    renderInput={(props) => <TextField {...props} />}
                  />
                </DemoContainer>
              </FormControl>
            </GridItem>
          </Grid>
          <Grid container spacing={3} style={{marginLeft: "1px"}}>
            <GridItem xs={12} sm={12} md={2}>
              <Button
                onClick={e => {
                  handeSubmit(e);
                }}
                color="info"
              >
                Buscar
              </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <Button
                onClick={e => {
                  exportData(reporte);
                }}
                color="info"
              >
                Descargar reporte
              </Button>
            </GridItem>
          </Grid>
        </Grid>
      </LocalizationProvider>
      <DataGrid
        rows={reporte}
        columns={columnReporte}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        autoHeight
        sx={{
          boxShadow: 2,
          border: 2,
          borderColor: 'primary.light',
          '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
          },
          '& .super-app-theme--header': {
            backgroundColor: 'primary.light',
          },
        }}
      />
        <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={openSnackbar} onClose={handleClose} autoHideDuration={6000}>
            <Alert onClose={handleClose} severity={color} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
        <Loader load={load} />
    </div>
  );
}