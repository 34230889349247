import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Provider } from 'react-redux'
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { store } from './helpers/store';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';

// core components
import Admin from "layouts/Admin.js";
import LoginPage from "views/LoginPage/LoginPage";

import "assets/css/material-dashboard-react.css?v=1.8.0";

const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        <PrivateRoute exact path="/admin/dashboard" component={Admin} />
        <PrivateRoute exact path="/admin/usuarios" component={Admin} />
        <PrivateRoute exact path="/admin/logout" component={Admin} />
        <PrivateRoute exact path="/admin/captura" component={Admin} />
        <PrivateRoute exact path="/admin/validacion" component={Admin} />
        <PrivateRoute exact path="/admin/reportecaptura" component={Admin} />
        <PrivateRoute exact path="/admin/reportegeneral" component={Admin} />
        <PrivateRoute exact path="/admin/reportediario" component={Admin} />
        <PrivateRoute exact path="/admin/cargamasiva" component={Admin} />
        <PrivateRoute exact path="/admin/reportependientes" component={Admin} />
        <PrivateRoute exact path="/admin/reporteprocesadas" component={Admin} />
        <PrivateRoute exact path="/admin/referidos" component={Admin} />
        <PrivateRoute exact path="/admin/reportereferidos" component={Admin} />
        <PrivateRoute exact path="/admin/reporteconversiones" component={Admin} />
        <PrivateRoute exact path="/admin/alta" component={Admin} />
        <PrivateRoute exact path="/admin/reporteejecutivos" component={Admin} />
        <Route path="/admin" component={Admin} />
        <Route path="/login" component={LoginPage} />
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
